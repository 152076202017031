import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery.cookie/jquery.cookie.js';
import 'jquery-validation/dist/jquery.validate.min.js';
import 'jquery-validation/dist/localization/messages_de.min.js';
import 'jquery-placeholder/jquery.placeholder.js';
import 'flexslider/jquery.flexslider-min.js';
import 'jquery.appear/jquery.appear.js';
import 'datatables.net/js/jquery.dataTables.js';
import 'metismenu/dist/metisMenu.min.js';
import 'ekko-lightbox/dist/ekko-lightbox.min.js';
import 'noty/js/noty/packaged/jquery.noty.packaged.min.js';
import './js/plugin/jquery.socialshareprivacy/jquery.socialshareprivacy.js';

import 'leaflet/dist/leaflet-src.js';
import 'leaflet.markercluster/dist/leaflet.markercluster-src.js';
import 'leaflet.featuregroup.subgroup/dist/leaflet.featuregroup.subgroup-src.js';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.js';
import 'leaflet.fullscreen/Control.FullScreen.js';
import 'leaflet-responsive-popup/leaflet.responsive.popup.js';
// import 'l.control.rose/dist/L.Control.Rose.min.js';
import './js/lib/shariff/shariff.min.js';

// import './js/ontopica/minimalJsMethods.js';
// import './js/ontopica/customfunctions.js';
// import './js/ontopica/voting.js';
// import './js/ontopica/polling.ranking.js';
// import './js/ontopica/ditoReporting.js';
// import './js/plugin/cookieCompliance/external.js';
// import './js/dist/ckeditor4/ckeditor.js';
// import './js/dist/ckeditor4/adapters/jquery.js';
window.jQuery = $;
window.$ = $;
